<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-14 16:29:32
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-13 23:26:49
-->
<template>
  <div class="page-box">
    <list @load="getList">
      <div class="order" v-for="(item, index) in list" :key="index">
        <order-card
          :info="item"
          :btns="btns"
          @onclickbtn="clickBtn"
          @ondetail="detail"
        />
      </div>
    </list>

    <turn-down
      v-model="showTurnDown"
      @onsubmit="submitTurnDown"
      ref="turnDown"
    ></turn-down>

    <msg-box v-model="showNone" @onclick="$router.back()" />
  </div>
</template>

<script>
import turnDown from "@/components/turnDown/turnDown";
import orderCard from "@/components/OrderCard/OrderCard";
import { orderList } from "../../../utils/api/otherWorkOrder";
import { order } from "../../../utils/api/service";
import pageLoader from "../../../utils/load_per_page";
import { List, Toast } from "vant";
import msgBox from "../../../components/msgBox/msgBox";
export default {
  components: {
    "turn-down": turnDown,
    "order-card": orderCard,
    list: List,
    "msg-box": msgBox,
  },
  data() {
    return {
      listLoader: null,

      list: [],

      showNone: false, // 显示没有列表

      prepareTurnDownId: 0, // 准备驳回的id
      showTurnDown: false, // 显示驳回弹窗

      btns: [
        { text: "驳回", method: "turnDown" },
        { text: "接单", type: "primary", method: "order" },
      ],
    };
  },

  methods: {
    // 获取列表
    getList() {
      if (!this.listLoader)
        this.listLoader = new pageLoader(
          orderList,
          "data.data.list.data",
          true,
          "data.data.list.last_page"
        );
      this.listLoader
        .load({
          status: 0,
        })
        .then((res) => {
          if (res && res.length === 0) this.showNone = true;
          if (res && res.length) this.list = res;
        })
        .catch((res) => {});
    },

    // 重新获取列表
    reGetList() {
      this.listLoader.reLoad();
      this.getList();
    },

    // 点击按钮
    clickBtn(e) {
      if (!e.detail.method) return false;
      this[e.detail.method](e.info.id, e.info);
    },

    // 驳回
    turnDown(id, info) {
      this.prepareTurnDownId = info.id;
      this.showTurnDown = true;
    },

    // 发送驳回
    submitTurnDown(e) {
      order({
        id: this.prepareTurnDownId,
        review: 2,
        reason: e.detail.text,
      })
        .then((res) => {
          Toast.success("驳回成功");
          this.showTurnDown = false;
          this.$refs.turnDown.clear();
          this.reGetList();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 接单
    order(id, info) {
      order({
        id: id,
        review: 1,
      })
        .then((res) => {
          Toast.success("通过成功");
          this.reGetList();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 详情
    detail(e) {
      this.$router.push({
        name: "ReviewOrderDetail",
        query: {
          id: e.info.id,
        },
      });
    },
  },
};
</script> 

<style src="@/assets/css/review/newTicket.css" scoped>
</style>